import { useRouter } from "next/router";
import { CzIcon } from '@components/icons/new/cz-icon';
import { VnIcon } from '@components/icons/new/vn-icon';
import { ToggleLanguageIcon } from '@components/icons/new/toggle-language-icon';
import React, { useState, FC } from "react";

export const SwitchLanguage: FC<{url: string}> = ({url}) => {
  const router = useRouter()
  const [showToggleLanguage, setShowToggleLanguage] = useState(false);
  const  switchToggle = () => {
    setShowToggleLanguage(!showToggleLanguage)
  }

  return (
    <>
      { showToggleLanguage &&
        <div onClick={switchToggle} >
          <ToggleLanguageIcon url={url}/>
        </div>
      }
      {!showToggleLanguage &&
        <div onClick={switchToggle} className="cursor-pointer">
          { router?.locale == 'vi' ? <VnIcon /> : <CzIcon /> }
        </div>
      }
    </>
  )
}
