export const VnIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="15" fill="#FF0000" />
      <path
        d="M20 11.6667L21.871 17.4249L27.9255 17.4249L23.0273 20.9837L24.8982 26.7419L20 23.1831L15.1018 26.7419L16.9727 20.9837L12.0745 17.4249L18.1291 17.4249L20 11.6667Z"
        fill="#F6DA00"
      />
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#6AA543" />
    </svg>
  );
};
