import React, { FC } from "react";

type LogoProps = {
  width?: number;
  height?: number;
};

export const Logo: FC<LogoProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 134 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.48145 13.5671H20.0785L14.28 20.8254L8.48145 13.5671Z"
        fill="#6AA543"
      />
      <path
        d="M6.49121 38.7722H22.0693L14.2803 23.3062L6.49121 38.7722Z"
        fill="#6AA543"
      />
      <path
        d="M36.5799 6.12429C35.9453 5.51178 35.6279 4.71551 35.6279 3.79673C35.6279 2.87796 35.9453 2.08169 36.5799 1.46918C37.2146 0.85666 38.0512 0.519775 39.0609 0.519775C40.0706 0.519775 40.9072 0.826034 41.5418 1.40792C42.1765 2.02044 42.4938 2.75546 42.4938 3.64361C42.4938 4.62363 42.1765 5.4199 41.5418 6.06304C40.9072 6.70618 40.0706 7.01244 39.0609 7.01244C38.0512 7.04307 37.2146 6.73681 36.5799 6.12429ZM36.3203 9.86064H41.8303V30.043H36.3203V9.86064Z"
        fill="#6AA543"
      />
      <path
        d="M62.3126 17.0581C63.4665 18.4056 64.0435 20.0594 64.0435 22.0194C64.0435 23.5507 63.6684 24.9595 62.9184 26.2458C62.1683 27.5321 61.0144 28.5734 59.4854 29.339C57.9565 30.1046 56.0525 30.5028 53.8023 30.5028C52.0714 30.5028 50.3405 30.2578 48.6673 29.7678C46.9941 29.2778 45.5517 28.5734 44.3689 27.6852L46.5902 23.0301C47.5422 23.7651 48.6096 24.347 49.8501 24.7758C51.0906 25.1739 52.3599 25.3883 53.6581 25.3883C55.1005 25.3883 56.2256 25.082 57.0622 24.5001C57.8988 23.9182 58.3027 23.0913 58.3027 22.0194C58.3027 19.8756 56.7737 18.8037 53.6869 18.8037H51.0617V14.7918L56.139 8.66661H45.6094V3.79712H62.9184V7.74784L57.3507 14.4855C59.4854 14.853 61.1586 15.7105 62.3126 17.0581Z"
        fill="#243E85"
      />
      <path
        d="M83.0548 17.0581C84.2087 18.4056 84.7857 20.0594 84.7857 22.0194C84.7857 23.5507 84.4106 24.9595 83.6606 26.2458C82.9105 27.5321 81.7566 28.5734 80.2276 29.339C78.6987 30.1046 76.7947 30.5028 74.5445 30.5028C72.8136 30.5028 71.0827 30.2578 69.4095 29.7678C67.7363 29.2778 66.2939 28.5734 65.1111 27.6852L67.3324 23.0301C68.2844 23.7651 69.3518 24.347 70.5923 24.7758C71.8328 25.1739 73.1021 25.3883 74.4003 25.3883C75.8427 25.3883 76.9678 25.082 77.8044 24.5001C78.641 23.9182 79.0448 23.0913 79.0448 22.0194C79.0448 19.8756 77.5159 18.8037 74.4291 18.8037H71.8039V14.7918L76.8812 8.66661H66.3516V3.79712H83.6606V7.74784L78.0929 14.4855C80.2276 14.853 81.9008 15.7105 83.0548 17.0581Z"
        fill="#243E85"
      />
      <path
        d="M88.2467 29.2781C87.5832 28.5737 87.2659 27.7162 87.2659 26.6443C87.2659 25.5723 87.5832 24.7148 88.2467 24.0411C88.9102 23.3673 89.718 23.061 90.6988 23.061C91.6508 23.061 92.4874 23.3979 93.1221 24.0411C93.7856 24.7148 94.1029 25.5723 94.1029 26.6443C94.1029 27.6855 93.7856 28.5737 93.1221 29.2781C92.4586 29.9825 91.6508 30.3193 90.6988 30.3193C89.718 30.35 88.9102 29.9825 88.2467 29.2781Z"
        fill="#243E85"
      />
      <path
        d="M101.345 29.0026C99.7295 28.1144 98.4601 26.8894 97.5658 25.2968C96.6715 23.7349 96.21 21.928 96.21 19.9373C96.21 17.9466 96.6715 16.1397 97.5658 14.5778C98.4601 13.0159 99.7295 11.7602 101.345 10.8721C102.96 9.98394 104.778 9.55518 106.797 9.55518C108.788 9.55518 110.548 10.0146 112.048 10.8721C113.548 11.7602 114.644 13.0159 115.308 14.6697L111.038 17.1197C110.057 15.2822 108.615 14.3328 106.768 14.3328C105.326 14.3328 104.143 14.8228 103.191 15.8335C102.239 16.8441 101.778 18.1916 101.778 19.9067C101.778 21.6217 102.239 22.9999 103.191 23.9799C104.143 24.9906 105.326 25.4806 106.768 25.4806C108.644 25.4806 110.086 24.5618 111.038 22.6936L115.308 25.1743C114.615 26.7669 113.548 28.0225 112.048 28.9107C110.548 29.7988 108.817 30.2582 106.797 30.2582C104.778 30.3501 102.932 29.8907 101.345 29.0026Z"
        fill="#243E85"
      />
      <path
        d="M134 25.542V30.044H117.037V26.522L126.701 14.3636H117.268V9.86157H133.683V13.3835L123.99 25.542H134Z"
        fill="#243E85"
      />
      <path
        d="M38.5417 40.2114L37.0416 41.804V43.8253H36.2915V36.1382H37.0416V40.7933L41.3111 36.1382H42.1766L39.0609 39.6295L42.4074 43.8253H41.4842L38.5417 40.2114Z"
        fill="#6AA543"
      />
      <path
        d="M47.9748 41.1608H43.4168C43.4456 41.7733 43.6764 42.2327 44.0803 42.6002C44.4842 42.9677 44.9746 43.1515 45.5804 43.1515C45.9266 43.1515 46.2151 43.0902 46.5035 42.9677C46.792 42.8452 47.0228 42.6615 47.2248 42.4164L47.6286 42.9065C47.3978 43.2127 47.0805 43.4577 46.7343 43.6109C46.3593 43.764 45.9843 43.8559 45.5515 43.8559C45.0034 43.8559 44.513 43.7334 44.0803 43.4883C43.6476 43.2433 43.3014 42.8758 43.0706 42.4471C42.8398 41.9877 42.6956 41.4977 42.6956 40.9464C42.6956 40.3952 42.811 39.8745 43.0417 39.4458C43.2725 38.9864 43.5899 38.6495 43.9937 38.4045C44.3976 38.1595 44.8592 38.037 45.3496 38.037C45.84 38.037 46.3016 38.1595 46.7055 38.4045C47.1094 38.6495 47.4267 38.9864 47.6575 39.4458C47.8883 39.8745 48.0037 40.3951 48.0037 40.977L47.9748 41.1608ZM44.0226 39.2007C43.6764 39.5683 43.4745 40.0276 43.4168 40.5789H47.2824C47.2248 39.997 47.0228 39.5376 46.6766 39.2007C46.3305 38.8332 45.8689 38.6801 45.3496 38.6801C44.8303 38.6801 44.3688 38.8639 44.0226 39.2007ZM46.3593 37.1488L45.3496 36.5057L44.3399 37.1488H43.6476L45.0034 36.1075H45.7246L47.0805 37.1488H46.3593ZM47.5421 35.0356H48.4364L46.9363 36.23H46.2728L47.5421 35.0356Z"
        fill="#6AA543"
      />
      <path
        d="M52.3313 43.4579C52.1871 43.5804 52.014 43.7029 51.812 43.7642C51.6101 43.8254 51.4082 43.856 51.1774 43.856C50.6581 43.856 50.2831 43.7029 49.9946 43.4273C49.7061 43.121 49.5907 42.7229 49.5907 42.2023V38.7109H48.6099V38.0372H49.5907V36.7815H50.3119V38.0372H51.9563V38.7109H50.3119V42.141C50.3119 42.4779 50.3985 42.7535 50.5427 42.9373C50.7158 43.121 50.9466 43.2129 51.2351 43.2129C51.3793 43.2129 51.5236 43.1823 51.6678 43.121C51.812 43.0598 51.9274 42.9985 52.0428 42.9066L52.3313 43.4579Z"
        fill="#6AA543"
      />
      <path
        d="M60.7836 38.6191C61.1875 39.0478 61.3895 39.6604 61.3895 40.4566V43.7948H60.6682V40.5179C60.6682 39.9054 60.524 39.446 60.2355 39.1397C59.947 38.8335 59.5432 38.6803 59.0239 38.6803C58.4469 38.6803 57.9853 38.8641 57.6392 39.2316C57.293 39.5991 57.1199 40.1197 57.1199 40.7629V43.7948H56.3987V38.0066H57.091V39.0785C57.293 38.7416 57.5815 38.466 57.9276 38.2516C58.2738 38.0678 58.7066 37.9453 59.1681 37.9453C59.8316 38.0066 60.3798 38.2209 60.7836 38.6191Z"
        fill="#6AA543"
      />
      <path
        d="M64.2165 43.4883C63.7837 43.2433 63.4664 42.8758 63.2356 42.4471C63.0048 41.9877 62.8606 41.4977 62.8606 40.9464C62.8606 40.3952 62.976 39.8745 63.2356 39.4458C63.4664 38.9864 63.8126 38.6495 64.2165 38.4045C64.6203 38.1595 65.1108 38.037 65.63 38.037C66.1493 38.037 66.6397 38.1595 67.0436 38.4045C67.4475 38.6495 67.7936 38.9864 68.0244 39.4458C68.2552 39.9051 68.3706 40.3952 68.3706 40.9464C68.3706 41.4977 68.2552 42.0183 68.0244 42.4471C67.7936 42.9065 67.4475 43.2433 67.0436 43.4883C66.6109 43.7334 66.1493 43.8559 65.63 43.8559C65.1108 43.8865 64.6203 43.7334 64.2165 43.4883ZM66.6686 42.9065C66.9859 42.7227 67.2167 42.4471 67.3898 42.1102C67.5629 41.7733 67.6494 41.3752 67.6494 40.9464C67.6494 40.5177 67.5629 40.1195 67.3898 39.7826C67.2167 39.4458 66.9859 39.1701 66.6686 38.9864C66.3512 38.8026 66.0051 38.7107 65.63 38.7107C65.255 38.7107 64.9088 38.8026 64.5915 38.9864C64.2742 39.1701 64.0434 39.4458 63.8703 39.7826C63.6972 40.1195 63.6106 40.5177 63.6106 40.9464C63.6106 41.3752 63.6972 41.7733 63.8703 42.1102C64.0434 42.4471 64.2742 42.7227 64.5915 42.9065C64.9088 43.0902 65.255 43.1821 65.63 43.1821C66.0051 43.1821 66.3512 43.0902 66.6686 42.9065ZM66.6109 37.1488L65.6012 36.5057L64.5915 37.1488H63.8991L65.255 36.1075H65.9762L67.3321 37.1488H66.6109ZM67.8225 35.0356H68.7168L67.2167 36.23H66.5532L67.8225 35.0356Z"
        fill="#6AA543"
      />
      <path
        d="M69.8708 36.6286C69.7554 36.5061 69.7266 36.3836 69.7266 36.2305C69.7266 36.0773 69.7843 35.9548 69.8708 35.8323C69.9862 35.7098 70.1016 35.6792 70.2458 35.6792C70.3901 35.6792 70.5343 35.7405 70.6209 35.8323C70.7362 35.9242 70.7651 36.0773 70.7651 36.1998C70.7651 36.353 70.7074 36.5061 70.6209 36.598C70.5055 36.7205 70.3901 36.7511 70.2458 36.7511C70.1016 36.7817 69.9862 36.7205 69.8708 36.6286ZM69.8997 38.0374H70.6209V43.8257H69.8997V38.0374Z"
        fill="#6AA543"
      />
      <path
        d="M78.2371 43.4579C78.0928 43.5804 77.9197 43.7029 77.7178 43.7642C77.5158 43.8254 77.3139 43.856 77.0831 43.856C76.5639 43.856 76.1888 43.7029 75.9003 43.4273C75.6119 43.121 75.4965 42.7229 75.4965 42.2023V38.7109H74.5156V38.0372H75.4965V36.7815H76.2177V38.0372H77.862V38.7109H76.2177V42.141C76.2177 42.4779 76.3042 42.7535 76.4485 42.9373C76.6215 43.121 76.8523 43.2129 77.1408 43.2129C77.2851 43.2129 77.4293 43.1823 77.5735 43.121C77.7178 43.0598 77.8332 42.9985 77.9486 42.9066L78.2371 43.4579Z"
        fill="#6AA543"
      />
      <path
        d="M83.9775 38.6189C84.3814 39.0477 84.5833 39.6602 84.5833 40.4564V43.7946H83.8621V40.5177C83.8621 39.9052 83.7179 39.4458 83.4294 39.1395C83.1409 38.8333 82.737 38.6801 82.2177 38.6801C81.6408 38.6801 81.1792 38.8639 80.833 39.2314C80.4868 39.5989 80.3137 40.1196 80.3137 40.7627V43.7946H79.5925V35.6482H80.3137V39.017C80.5157 38.6801 80.7753 38.4351 81.1503 38.2514C81.4965 38.0676 81.9004 37.9757 82.362 37.9757C83.0255 38.0064 83.5736 38.2208 83.9775 38.6189Z"
        fill="#6AA543"
      />
      <path
        d="M90.1219 38.5577C90.497 38.9252 90.6989 39.5071 90.6989 40.2421V43.8253H90.0065V42.9372C89.8335 43.2434 89.6027 43.4578 89.2853 43.6416C88.968 43.8253 88.593 43.8866 88.1314 43.8866C87.5256 43.8866 87.0352 43.7335 86.689 43.4272C86.3428 43.1209 86.1409 42.7228 86.1409 42.2022C86.1409 41.7122 86.314 41.314 86.6313 41.0078C86.9775 40.7015 87.4967 40.5484 88.218 40.5484H89.9489V40.2115C89.9489 39.7215 89.8046 39.3233 89.545 39.0783C89.2853 38.8333 88.9103 38.6802 88.391 38.6802C88.0449 38.6802 87.7275 38.7415 87.4102 38.864C87.0929 38.9865 86.8332 39.1396 86.6024 39.354L86.2851 38.7721C86.5736 38.5271 86.8909 38.3433 87.266 38.1902C87.641 38.0677 88.0449 37.9758 88.4776 37.9758C89.1988 38.0064 89.7181 38.1902 90.1219 38.5577ZM86.7755 35.7708H87.7564L89.1699 37.1183H88.4487L86.7755 35.7708ZM89.2853 42.9678C89.5738 42.7534 89.8046 42.4778 89.9489 42.1103V41.1609H88.2468C87.3236 41.1609 86.8621 41.4978 86.8621 42.2022C86.8621 42.539 86.9775 42.8147 87.2371 42.9984C87.4679 43.1822 87.8141 43.2741 88.2468 43.2741C88.6218 43.2741 88.9969 43.1516 89.2853 42.9678Z"
        fill="#6AA543"
      />
      <path
        d="M97.0737 38.6191C97.4775 39.0478 97.6795 39.6604 97.6795 40.4566V43.7948H96.9583V40.5179C96.9583 39.9054 96.814 39.446 96.5255 39.1397C96.2371 38.8335 95.8332 38.6803 95.3139 38.6803C94.7369 38.6803 94.2754 38.8641 93.9292 39.2316C93.583 39.5991 93.4099 40.1197 93.4099 40.7629V43.7948H92.6887V38.0066H93.3811V39.0785C93.583 38.7416 93.8715 38.466 94.2177 38.2516C94.5639 38.0678 94.9966 37.9453 95.4582 37.9453C96.1217 38.0066 96.6698 38.2209 97.0737 38.6191Z"
        fill="#6AA543"
      />
      <path
        d="M104.084 38.6189C104.488 39.0477 104.69 39.6602 104.69 40.4564V43.7946H103.969V40.5177C103.969 39.9052 103.825 39.4458 103.536 39.1395C103.248 38.8333 102.844 38.6801 102.325 38.6801C101.748 38.6801 101.286 38.8639 100.94 39.2314C100.594 39.5989 100.421 40.1196 100.421 40.7627V43.7946H99.6995V35.6482H100.421V39.017C100.623 38.6801 100.882 38.4351 101.257 38.2514C101.603 38.0676 102.007 37.9757 102.469 37.9757C103.132 38.0064 103.681 38.2208 104.084 38.6189Z"
        fill="#6AA543"
      />
      <path
        d="M110.201 43.4886C109.768 43.2436 109.451 42.8761 109.191 42.4473C108.96 41.9879 108.816 41.4979 108.816 40.916C108.816 40.3341 108.931 39.8441 109.191 39.4154C109.422 38.956 109.768 38.6191 110.201 38.3741C110.633 38.1291 111.095 38.0066 111.643 38.0066C112.105 38.0066 112.537 38.0985 112.912 38.2822C113.287 38.466 113.576 38.7722 113.778 39.1397L113.23 39.5379C113.057 39.2622 112.826 39.0479 112.537 38.8947C112.249 38.7416 111.96 38.6804 111.643 38.6804C111.239 38.6804 110.893 38.7722 110.576 38.956C110.258 39.1397 110.028 39.4154 109.854 39.7523C109.681 40.0891 109.595 40.4873 109.595 40.916C109.595 41.3754 109.681 41.7429 109.854 42.1104C110.028 42.4473 110.287 42.723 110.576 42.9067C110.893 43.0905 111.239 43.1823 111.643 43.1823C111.96 43.1823 112.278 43.1211 112.537 42.968C112.826 42.8148 113.028 42.6311 113.23 42.3248L113.778 42.723C113.576 43.0905 113.259 43.3661 112.912 43.5805C112.537 43.7642 112.133 43.8561 111.672 43.8561C111.095 43.8867 110.605 43.7642 110.201 43.4886Z"
        fill="#6AA543"
      />
      <path
        d="M115.912 43.4883C115.479 43.2433 115.162 42.8758 114.931 42.4471C114.7 41.9877 114.556 41.4977 114.556 40.9464C114.556 40.3951 114.672 39.8745 114.931 39.4457C115.162 38.9863 115.508 38.6495 115.912 38.4045C116.316 38.1595 116.806 38.0369 117.326 38.0369C117.845 38.0369 118.335 38.1595 118.739 38.4045C119.172 38.6495 119.489 38.9863 119.72 39.4457C119.951 39.9051 120.066 40.3951 120.066 40.9464C120.066 41.4977 119.951 42.0183 119.72 42.4471C119.489 42.9064 119.143 43.2433 118.739 43.4883C118.306 43.7333 117.845 43.8558 117.326 43.8558C116.806 43.8865 116.345 43.7333 115.912 43.4883ZM118.364 42.9064C118.681 42.7227 118.912 42.4471 119.085 42.1102C119.258 41.7733 119.345 41.3752 119.345 40.9464C119.345 40.5176 119.258 40.1195 119.085 39.7826C118.912 39.4457 118.681 39.1701 118.364 38.9863C118.047 38.8026 117.701 38.7107 117.326 38.7107C116.951 38.7107 116.604 38.8026 116.287 38.9863C115.97 39.1701 115.739 39.4457 115.566 39.7826C115.393 40.1195 115.306 40.5176 115.306 40.9464C115.306 41.3752 115.393 41.7733 115.566 42.1102C115.739 42.4471 115.97 42.7227 116.287 42.9064C116.604 43.0902 116.951 43.1821 117.326 43.1821C117.729 43.1821 118.076 43.0902 118.364 42.9064ZM118.335 37.1488L117.326 36.23L116.316 37.1488H115.624L116.951 35.8013H117.701L119.028 37.1488H118.335Z"
        fill="#6AA543"
      />
      <path
        d="M125.98 38.6191C126.384 39.0478 126.586 39.6604 126.586 40.4566V43.7948H125.865V40.5179C125.865 39.9054 125.721 39.446 125.432 39.1397C125.144 38.8335 124.74 38.6803 124.221 38.6803C123.644 38.6803 123.182 38.8641 122.836 39.2316C122.49 39.5991 122.317 40.1197 122.317 40.7629V43.7948H121.595V38.0066H122.288V39.0785C122.49 38.7416 122.778 38.466 123.124 38.2516C123.471 38.0678 123.903 37.9453 124.365 37.9453C125.057 38.0066 125.577 38.2209 125.98 38.6191Z"
        fill="#6AA543"
      />
      <path
        d="M133.654 38.0372V43.1211C133.654 44.1011 133.424 44.8361 132.991 45.2955C132.558 45.7549 131.866 45.9999 130.943 45.9999C130.452 45.9999 129.962 45.908 129.5 45.7549C129.039 45.6018 128.692 45.3874 128.404 45.1118L128.779 44.5299C129.039 44.7749 129.356 44.9586 129.731 45.1118C130.106 45.2649 130.51 45.3261 130.914 45.3261C131.606 45.3261 132.097 45.1424 132.443 44.8055C132.76 44.4686 132.933 43.948 132.933 43.213V42.478C132.702 42.8455 132.414 43.1211 132.039 43.3048C131.664 43.4886 131.26 43.5805 130.827 43.5805C130.308 43.5805 129.846 43.458 129.443 43.213C129.039 42.968 128.692 42.6311 128.462 42.2023C128.231 41.7736 128.115 41.2835 128.115 40.7629C128.115 40.2116 128.231 39.7523 128.462 39.3235C128.692 38.8947 129.039 38.5579 129.443 38.3435C129.846 38.0985 130.308 38.0066 130.827 38.0066C131.289 38.0066 131.693 38.0985 132.068 38.3128C132.443 38.4966 132.731 38.8029 132.962 39.1704V38.0678H133.654V38.0372ZM131.924 42.6311C132.241 42.4473 132.5 42.2023 132.674 41.8654C132.847 41.5286 132.933 41.1917 132.933 40.7629C132.933 40.3648 132.847 39.9973 132.674 39.6604C132.5 39.3541 132.241 39.0785 131.952 38.9254C131.635 38.7416 131.289 38.6497 130.885 38.6497C130.481 38.6497 130.135 38.7416 129.818 38.9254C129.5 39.1091 129.269 39.3541 129.096 39.6604C128.923 39.9973 128.837 40.3341 128.837 40.7629C128.837 41.161 128.923 41.5286 129.096 41.8654C129.269 42.2023 129.529 42.4473 129.818 42.6311C130.135 42.8148 130.481 42.9067 130.885 42.9067C131.289 42.9067 131.606 42.8148 131.924 42.6311Z"
        fill="#6AA543"
      />
      <path
        d="M0 0H12.9818V2.75632H2.65405C2.74059 3.70572 2.7406 4.62449 2.88484 5.51264C3.43296 9.55523 4.99077 13.1078 7.61597 16.1398C8.53912 17.181 9.51996 18.1611 10.4431 19.2023C11.9144 20.7949 11.8278 23.1837 10.3566 24.7762C9.02954 26.185 7.67367 27.5938 6.51973 29.1251C4.44265 31.912 3.25987 35.1277 2.85599 38.6497C2.76944 39.4153 2.74059 40.2116 2.65405 41.0385H12.9818V43.8255H0.0576964C0.0865448 43.1211 0.0865456 42.4473 0.144242 41.7429C0.375029 38.7416 1.03854 35.8628 2.19248 33.1371C3.54835 29.8601 5.48119 27.0119 7.90445 24.5312C8.30833 24.1025 8.74105 23.6737 9.14493 23.2143C9.86614 22.4181 9.86614 21.3768 9.14493 20.5499C7.8756 19.1411 6.54858 17.7936 5.39464 16.2623C2.65405 12.6484 0.980844 8.48333 0.34618 3.85884C0.17309 2.60319 0.115393 1.31691 0 0Z"
        fill="#243E85"
      />
      <path
        d="M25.906 2.75632H15.5783V0H28.5024C28.4735 0.79627 28.4447 1.56191 28.387 2.35818C28.0985 5.54326 27.3196 8.57521 26.0214 11.454C24.6656 14.4247 22.8481 17.0279 20.6268 19.3249C20.2229 19.723 19.8479 20.1517 19.444 20.5805C18.6651 21.4074 18.694 22.4487 19.444 23.2756C20.5691 24.5312 21.7807 25.7256 22.8193 27.0732C25.733 30.7789 27.4927 35.0359 28.1851 39.8135C28.3582 41.1304 28.4447 42.4779 28.5601 43.8255H15.5783V41.0691H25.8195C25.8195 40.5179 25.8484 40.0279 25.8195 39.5072C25.3868 34.454 23.4539 30.1664 20.021 26.6444C19.4152 26.0319 18.8382 25.3888 18.2612 24.7762C16.6746 23.1224 16.6746 20.7336 18.2324 19.0492C19.0113 18.1917 19.819 17.3648 20.598 16.5073C23.3962 13.4753 25.0694 9.83087 25.6753 5.63514C25.8195 4.71637 25.8195 3.76697 25.906 2.75632Z"
        fill="#243E85"
      />
    </svg>
  );
};
